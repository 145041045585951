<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group
            label="Nombre completo"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre completo"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre completo"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="fields.length > 0"
        class="align-content-center mx-auto d-flex flex-column"
      >
        <b-col
          v-for="(input, index) in fields"
          :key="`fieldsInput-${index}`"
          lg="12"
          md="12"
          sm="12"
        >
          <b-row>
            <b-col
              lg="5"
              md="5"
              sm="5"
              style="margin-bottom: 10px;"
            >
              <b-form-input
                type="text"
                v-model="input.item"
                :placeholder="`Item ${index+1}`"
              />
            </b-col>
            <b-col
              lg="5"
              md="5"
              sm="5"
              style="margin-bottom: 10px;"
            >
              <b-form-input
                type="text"
                v-model="input.value"
                :placeholder="`Valor ${index+1}`"
              />
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="2"
              cols="2"
              class="text-right"
            >
              <div class="d-inline-block position-relative">
                <button
                  v-if="fields.length > 0"
                  class="btn btn-md btn-danger width-40px m-0 p-0 mr-1"
                  type="button"
                  @click="removeField(index)"
                >
                  <feather-icon
                    class="position-relative mx-auto text-center justify-content-center d-block"
                    icon="MinusIcon"
                  />
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column mb-1">
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="btn btn-md btn-success width-40px m-0 p-0"
            type="button"
            @click="addField"
          >
            <feather-icon
              class="position-relative mx-auto text-center justify-content-center d-block"
              icon="PlusIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('access.design.store') : accessPermissions('access.design.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="close"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import AccessService from '../services/AccessService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      name: '',
      fields: [],
      required,
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    addField() {
      this.fields.push({ item: '', value: '' })
    },
    removeField(number) {
      this.fields.forEach((item, index, map) => {
        if (index === number) map.splice(index, 1)
      })
    },
    clearForm() {
      this.name = ''
      this.fields = []
      this.$refs.formValidation.reset()
    },
    close() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('access.design.show')) {
        await AccessService.handleEdit(this.id).then(({ data }) => {
          this.name = data.data.name
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            name: this.name,
            fields: this.fields.length === 0 ? '' : this.fields,
          }

          if (this.id === undefined && this.accessPermissions('access.design.store')) {
            AccessService.handleStore(param).then(({ data }) => {
              if (data.data) {
                this.swal('El dispositivo ha sido registrado correctamente', 'success')
                this.close()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('access.design.update')) {
            AccessService.handleUpdate(this.id, param).then(({ data }) => {
              if (data.data) {
                this.swal('El dispositivo ha sido actualizado correctamente', 'success')
                this.close()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
